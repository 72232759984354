import React, { type FC, useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';

import useStore from '@/store';

import { clearStorage } from '@/services/storage';

import { SidebarItem, Icon, Text, Container } from './styles';

interface ItemsProps {
  firstAccess: boolean;
  isFranchise: boolean;
  isOwner: boolean;
  updateGetList: (update: boolean) => void;
  toogle?: () => void;
}

export const Items: FC<ItemsProps> = ({
  firstAccess,
  isFranchise,
  isOwner,
  updateGetList,
  toogle,
}) => {
  const { updateEstablishment, updateUserName, updateRole } = useStore(
    state => state
  );
  const router = useRouter();
  const [previousPath, setPreviousPath] = useState(router.asPath);

  const signOut = () => {
    updateGetList(false);
    updateEstablishment(null);
    updateUserName('');
    updateRole(null);
    clearStorage('daypass_access_token');
    clearStorage('daypass-user');

    router.push('/login');
  };

  useEffect(() => {
    if (router.asPath !== previousPath) {
      if (toogle) {
        toogle();
      }
      setPreviousPath(router.asPath);
    }
  }, [router.asPath]);

  return (
    <>
      {!firstAccess && (
        <>
          <Link href={!isFranchise ? '/' : '/financeiro'} passHref>
            <SidebarItem>
              <Container>
                <Icon>
                  <Image
                    src="/icons/Home.svg"
                    alt="Home"
                    width={25}
                    height={25}
                  />
                </Icon>
                <Text className="items">Home</Text>
              </Container>
            </SidebarItem>
          </Link>
          <Link href="/dados" passHref>
            <SidebarItem>
              <Container>
                <Icon>
                  <Image
                    src="/icons/MeusDados.svg"
                    alt="Meus Dados"
                    width={20}
                    height={20}
                  />
                </Icon>
                <Text className="items">Meus dados</Text>
              </Container>
            </SidebarItem>
          </Link>
          {(isOwner || isFranchise) && (
            <>
              <Link href="/financeiro" passHref>
                <SidebarItem>
                  <Container>
                    <Icon>
                      <Image
                        src="/icons/Financeiro.svg"
                        alt="Financeiro"
                        width={16}
                        height={16}
                      />
                    </Icon>
                    <Text className="items">Financeiro</Text>
                  </Container>
                </SidebarItem>
              </Link>
              <Link href="/relatorios" passHref>
                <SidebarItem>
                  <Container>
                    <Icon>
                      <Image
                        src="/icons/MeusDados.svg"
                        alt="Relatórios"
                        width={16}
                        height={16}
                      />
                    </Icon>
                    <Text className="items">Relatórios</Text>
                  </Container>
                </SidebarItem>
              </Link>
            </>
          )}
          {!isFranchise && isOwner && (
            <>
              <Link href="/produtos" passHref>
                <SidebarItem>
                  <Container>
                    <Icon>
                      <Image
                        src="/icons/Produtos.svg"
                        alt="Produtos"
                        width={25}
                        height={25}
                      />
                    </Icon>
                    <Text className="items">Produtos</Text>
                  </Container>
                </SidebarItem>
              </Link>
              <Link href="/promos" passHref>
                <SidebarItem>
                  <Container>
                    <Icon>
                      <Image
                        src="/icons/Promocoes.svg"
                        alt="Promoções"
                        width={25}
                        height={25}
                      />
                    </Icon>
                    <Text className="items">Promoções</Text>
                  </Container>
                </SidebarItem>
              </Link>
            </>
          )}
          <Link href="/escolha" passHref>
            <SidebarItem>
              <Container>
                <Icon>
                  <Image
                    src="/icons/Unidades.svg"
                    alt="Unidades"
                    width={20}
                    height={20}
                  />
                </Icon>
                <Text className="items">Unidades</Text>
              </Container>
            </SidebarItem>
          </Link>
          {!isFranchise && (
            <>
              <Link href="/notificacoes" passHref>
                <SidebarItem>
                  <Container>
                    <Icon>
                      <Image
                        src="/icons/Notificacoes.svg"
                        alt="Notificações"
                        width={20}
                        height={20}
                      />
                    </Icon>
                    <Text className="items">Notificações</Text>
                  </Container>
                </SidebarItem>
              </Link>
              <Link href="/indicar" passHref>
                <SidebarItem>
                  <Container>
                    <Icon>
                      <Image
                        src="/icons/Indicar.svg"
                        alt="Indicar"
                        width={20}
                        height={20}
                      />
                    </Icon>
                    <Text className="items">Indicar</Text>
                  </Container>
                </SidebarItem>
              </Link>
            </>
          )}
        </>
      )}

      <Link href="#" passHref>
        <SidebarItem onClick={() => signOut()}>
          <Container>
            <Icon>
              <Image src="/icons/Sair.svg" alt="Sair" width={20} height={20} />
            </Icon>
            <Text className="items">Sair</Text>
          </Container>
        </SidebarItem>
      </Link>
    </>
  );
};

export default Items;
